let BASE_URL = '/easypaybff';

export const LOGIN_SERVICE_URL = BASE_URL + "/login";
export const LOGOUT_SERVICE_URL = BASE_URL + "/auth/logout";
export const GET_EXTERNAL_LOGOUT_URL = BASE_URL + "/auth/logoutUri";

/* BACK END */
export const GET_COUNTRIES_URL =  BASE_URL + '/country/all';
export const GET_ENABLED_COUNTRIES_URL =  BASE_URL + '/country/payEnabled';
export const SEND_PAYMENT_URL =  BASE_URL + '/pay/authorize';




