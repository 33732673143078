import React, { Component } from 'react';
import Button from '../ui-components/Button';
import plus from '../../img/plus.svg';
import CustomIcon from '../ui-components/CustomIcon';
import Circle from '../ui-components/Circle';
import Logo from '../ui-components/Logo';
import * as actions from '../../store/actions';
import { connect } from "react-redux";

class Homepage extends Component {

    componentWillUnmount = () => {
        this.props.setPageTransitionClassRight();
    }

    goToPayment = () =>  {
        this.props.setPrevPath('/');
        this.props.history.push('/payment');
    }

    render () {
        return(
            <div className="fullscreen row no-gutters home-page">
                <div className="bg-dark col-3"> 
                    <div className="logo white">
                        easypay
                    </div>
                    <div className="box card-home">
                        <p>Welcome</p>
                        {this.props.user ? <p>{this.props.user}</p> : null}
                        {this.props.isPaymentSuccess
                            ?   this.props.response.authorization ? 
                                    <div className="payment-completed">
                                        <CustomIcon iconClass="icon-clapping" />
                                        <p>Payment successfully completed!</p>
                                    </div> 
                                :   <div className="payment-completed">
                                        {/* <CustomIcon iconClass="icon-clapping" /> */}                                   
                                        <p>{this.props.response.message}</p>
                                    </div> 
                            :   null
                        }
                        <div className="add-payment-btn-container">
                            <div className="bg-button-round" />                          
                                <div className="button-round" onClick={this.goToPayment}>
                                    <img src={plus} />
                                    New payment
                                </div>                       
                        </div> 
                    </div>
                    <div className="logout-btn-container">
                        <CustomIcon iconClass="icon-logout" />
                        <Button handleClick={this.props.logout} btnClass="naked-button small-button" >Logout</Button>       
                    </div>                               
                </div>
                <div className="bg-light col-9">
                    <Circle circleClass="bottom" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {            
        isPaymentSuccess: state.payment.isPaymentSuccess,
        response: state.payment.response,
        user: state.authentication.user,
    }
};

const mapDispatchToProps = dispatch => {
    return {       
        setPrevPath: path => dispatch(actions.setPrevPath(path)),
        setPageTransitionClassRight: () => dispatch(actions.setPageTransitionClassRight()),
        logout:() => dispatch(actions.logout())
    };
}

export default connect(mapStateToProps, mapDispatchToProps) (Homepage);
