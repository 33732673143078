export const SHOW_ERROR_MESSAGE = 'SHOW_ERROR_MESSAGE';
export const HIDE_ERROR_MESSAGE = 'HIDE_ERROR_MESSAGE';

/* AUTHENTICATION */
export const LOGIN_START= "LOGIN_START";
export const LOGIN_SUCCESS= "LOGIN_SUCCESS";
export const LOGIN_FAILURE= "LOGIN_FAILURE";
export const LOGOUT= "LOGOUT";

/* PAYMENT */
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_ENABLE_COUNTRIES_SUCCESS = 'GET_ENABLE_COUNTRIES_SUCCESS';

export const SEND_PAYMENT_REQUEST = 'SEND_PAYMENT_REQUEST';
export const SEND_PAYMENT_SUCCESS = 'SEND_PAYMENT_SUCCESS';
export const SEND_PAYMENT_FAILURE = 'SEND_PAYMENT_FAILURE';

export const RESET_PAYMENT = 'RESET_PAYMENT';

/* ANIMATION */
export const SET_TRANSITION_CLASS_LEFT = 'SET_TRANSITION_CLASS_LEFT';
export const SET_TRANSITION_CLASS_RIGHT = 'SET_TRANSITION_CLASS_RIGHT';
export const SET_PREV_PATH = 'SET_PREV_PATH';