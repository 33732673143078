import React from 'react';
import Button from './Button'; 

const popup = props => (
    <div>
        <div className="popup-background" onClick={props.close}></div>
        <div className={"popup-container " + (props.popupClass ? props.popupClass : '')}>
            <div className="close-popup" onClick={props.close}> <span className="icon-plus"></span></div>
            <div className="popup-header">
                <h3>{props.title}</h3>                
            </div>
            <div className="popup-content">
                {props.children}
            </div>                   
            {props.btnName 
                ?   <div className="footer-popup">
                        <Button btnClass="btn-medium" handleClick={props.handleClick} disabled={props.disabled} isArrow>{props.btnName}</Button> 
                        {props.isDoubleButton ? <Button btnClass="btn-medium" handleClick={props.close} >{props.btnName2}</Button>:null}
                    </div> 
                :   null
            }
        </div>
    </div>
)

export default popup;