import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/Api';
import * as _ from 'lodash';

const initialState = {
    pageTransitionClass:"fade-left",
    prevPath: null
};

const setPageTransitionClassLeft = state => {
    return updateObject( state, {
        pageTransitionClass:"fade-left"
    });
};

const setPageTransitionClassRight = state => {
    return updateObject( state, {
        pageTransitionClass:"fade-right"
    });
};

const setPrevPath = (state, action) => {
    return updateObject( state, {
        prevPath: action.prevPath
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {  
        case actionTypes.SET_TRANSITION_CLASS_LEFT: return setPageTransitionClassLeft(state);
        case actionTypes.SET_TRANSITION_CLASS_RIGHT: return setPageTransitionClassRight(state);
        case actionTypes.SET_PREV_PATH: return setPrevPath(state, action);
        default:
            return state;
    }
};

export default reducer;