import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/Api';
import * as _ from 'lodash';

const initialState = {
    showError: false,
    error: null,
};

const showErrorMessage = (state, action) => {
    return updateObject( state, {
        showError: true,
        error: action.message
    });
};

const resetError = state => {
    return updateObject( state, {
        showError: false,
        error: null
    });
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {  
        case actionTypes.SHOW_ERROR_MESSAGE: return showErrorMessage(state, action);
        case actionTypes.HIDE_ERROR_MESSAGE: return resetError(state);
        default:
            return state;
    }
};

export default reducer;