import React from 'react';
 
const customIcon = props => (
    <div className={"icon-container " + (props.disabled ? ' disabled ': '')} onClick={props.handleClick}>
        <span className={"icon " + props.iconClass + (props.disabled ? ' disabled': '')}>            
        </span>       
    </div>
)

export default customIcon;
