import { combineReducers } from 'redux';

import paymentReducer from './paymentReducer';
import authenticationReducer from './authenticationReducer';
import errorReducer from './errorReducer';
import animationReducer from './animationReducer';

const rootReducer = combineReducers({ 
    authentication: authenticationReducer, 
    error: errorReducer,
    payment: paymentReducer,
    animation: animationReducer
});

export default rootReducer;