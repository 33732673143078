import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/Api';

const initialState = {
    isLoading: false,  
    user: null,
    error: null,
    isAuthenticated: false,
};

const getAuthenticationStart = state => {
    return updateObject(state, { isLoading: true, isAuthenticated: false, error: null })
};

const getAuthenticationSuccess = (state, action) => {
    return updateObject(state, { isLoading: false, error: null, isAuthenticated: true, user: action.user }) 
};

const getAuthenticationFail = (state, action) => {
    return updateObject(state, { isLoading: false, error: action.error, isAuthenticated: false })
};

const logout = state => {
    return updateObject(state, { isLoading: false, user: null, isAuthenticated: false, error: null })
};

const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {  
        case actionTypes.LOGIN_START: return getAuthenticationStart(state);
        case actionTypes.LOGIN_SUCCESS: return getAuthenticationSuccess(state, action);
        case actionTypes.LOGIN_FAILURE: return getAuthenticationFail(state, action);
        case actionTypes.LOGOUT: return logout(state, action);

        default:
            return state;
    }
};
export default reducer;