import React from 'react';

const loader = props => (
    <div className="loader-container">
        <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
)

export default loader;