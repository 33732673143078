import React from 'react';
import Popup from '../../ui-components/Popup';

const withErrorHandling = WrappedComponent => ({ showError, closeAlert, children }) => {
    return (
      <WrappedComponent>
        {showError && 
            <Popup popupClass="popup-small" close={closeAlert} btnName="Close" handleClick={closeAlert}> 
                <p className="error-message">An error occurred!</p>
                <p className="error-message"> Please try again later or contact website's administrator</p>
            </Popup>}
        
        {children}
      </WrappedComponent>
    );
};

const DivWithErrorHandling = withErrorHandling(({children}) => <div>{children}</div>)

export default DivWithErrorHandling;