import React, { Component } from 'react';
import { Route } from "react-router-dom";
import { connect } from 'react-redux';
 
class PrivateRoute extends Component {

    state = {
        renderComponent: false
    }

    componentDidMount= () => {
        if(this.props.isAuthenticated) {
            return this.setState({
                renderComponent: true
            })
        } 

        return this.goToLogin();
    }

    componentDidUpdate = (prevProps) => {
        if(prevProps.isAuthenticated !== this.props.isAuthenticated && this.props.isAuthenticated) {
            this.setState({
                renderComponent: true
            })
        }

        if(prevProps.isAuthenticated !== this.props.isAuthenticated && !this.props.isAuthenticated) {
            this.goToLogin();
            this.setState({
                renderComponent: false
            })
        }
    }

    goToLogin = () => {
        this.props.history.push('/login')
    }

    render () {
        let ComponentChild = this.props.component;
        return(
            this.state.renderComponent 
            ?   <Route
                    render={props => (
                        <ComponentChild {...props} />
                    )}
                />
            :   null            
        )
    }
}

const mapStateToProps = state => {
	return {		
		isAuthenticated: state.authentication.isAuthenticated
	};
};

export default connect(mapStateToProps) (PrivateRoute);