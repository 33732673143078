import React from 'react';
import circle from '../../img/cerchio.svg';
 
const Circle = props => (
    <div className={"circle " + (props.circleClass ? props.circleClass : '')}>
        <svg xmlns="http://www.w3.org/2000/svg" width="841" height="841" viewBox="0 0 841 841" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M420.5 841C652.736 841 841 652.736 841 420.5C841 188.264 652.736 0 420.5 0C188.264 0 0 188.264 0 420.5C0 652.736 188.264 841 420.5 841ZM421 742C604.359 742 753 593.359 753 410C753 226.641 604.359 78 421 78C237.641 78 89 226.641 89 410C89 593.359 237.641 742 421 742Z" fill="#EE4D2A"/>
        </svg>
    </div>
)

export default Circle;