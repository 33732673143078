import React from 'react';
import Select from 'react-select';
import CustomIcon from './CustomIcon';
 
const customSelect = (props) => {
    const checkMulti = props.isMulti && (props.value && props.value.length > 1);
    return (        
        <div>
            <Select 
                options={props.options}           
                isMulti={props.isMulti} 
                classNamePrefix="react-select" 
                className={"react-select " + (props.selectClass ? props.selectClass : '') + (checkMulti ? ' multiLabel' : '')}
                placeholder={props.placeholder}
                isDisabled = {props.disabled} 
                hideSelectedOptions={false}
                isLoading={props.isLoading}
                // menuIsOpen
                // autoFocus
                value = {props.value}
                onChange={props.handleChange}
                />
            {props.styleType === 'right-icon'
                ?   <CustomIcon iconClass={props.iconClass}/>
                : null}
        </div>
        
    )
}
export default customSelect;