import * as actionTypes from './actionTypes';

export const showError = message => {
    return { 
        type: actionTypes.SHOW_ERROR_MESSAGE,
        message
    };
};

export const closeAlert = () => {
    return { 
        type: actionTypes.HIDE_ERROR_MESSAGE,       
    };
};