import React from 'react';
import ReactDOM from 'react-dom';
import './styles/sass/index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from "redux";
import thunk from 'redux-thunk';
import { loadState, saveState } from './services/localStorage';
import rootReducer from "./store/reducers";
import { BrowserRouter as Router } from "react-router-dom";
import { throttle } from 'lodash';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadState();
const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(thunk)));

// store.subscribe(throttle(() => {
// 	saveState({
// 		authentication: {
//             user: store.getState().authentication.user,
//             isAuthenticated: store.getState().authentication.isAuthenticated
//         }
// 	});
// }, 1000));

ReactDOM.render(
    <Provider store={store}>
        <Router basename={'/'}>
            <App />
        </Router>
    </Provider>
    , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
