import React from 'react';
import { Link } from "react-router-dom";

const logo = props => (
    <Link to="/">
        <div className={"logo " + (props.logoClass ? props.logoClass : '')}>
            easypay
        </div>
    </Link>
)

export default logo;