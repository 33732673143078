import React, { Component } from 'react';
import Input from '../ui-components/Input';
import Button from '../ui-components/Button';
import CustomSelect from '../ui-components/CustomSelect';
import Circle from '../ui-components/Circle';
import Loader from '../ui-components/Loader';
import Logo from '../ui-components/Logo';
import { capitalize } from '../../services/Api';
import * as actions from '../../store/actions';
import { connect } from "react-redux";
import { regex } from '../../services/utils';


const arrayCustomer = [
    {placeholder:'First Name', value:'firstName'},
    {placeholder:'Last Name', value:'lastName'},
    {placeholder:'Address', value:'street'},
    {placeholder:'Zip Code', value:'postalCode'},
    {placeholder:'Town or City', value:'city'},
    {placeholder:'State', value:'state'},
    {placeholder:'Country', value:'countryCustomer'},
    {placeholder:'Email', value:'email'},
    {placeholder:'Phone', value:'phoneNumber'},
]

const arrayCC = [
    {placeholder:'Card Number', value:'cardNumber'},
    {placeholder:'CVV', value:'cvNumber'},
    {placeholder:'Exp Month', value:'expirationMonth'},
    {placeholder:'Exp Year', value:'expirationYear'},
    // {placeholder:'Name on card', value:'city'}
]

const objIcons = {
    firstName:'icon-user', 
    lastName: 'icon-user',
    street: 'icon-address',
    postalCode: 'icon-address',
    city: 'icon-address',
    state: 'icon-address',
    countryCustomer: 'icon-address',
    email: 'icon-arroba',
    phoneNumber: 'icon-phone-call',
    country: 'icon-earth',
    totalAmount: 'icon-coin',
    cardNumber: 'icon-credit-card',
    cvNumber: 'icon-key',
    expirationMonth: 'icon-calendar',
    expirationYear: 'icon-calendar',
    sapOrderId: 'icon-invoice'
}

const objCurrency = {
    'EUR': '€',
    'CAD': 'CA$',
    'DKK': 'kr',
    'GBP': '£',
    'PLN': 'zł',
    'SEK': 'ky',
    'USD': '$'
}
    	  					

class PaymentPage extends Component {

    state = {
        totalAmount: '',
        country: '',
        currency: null, // not to send to BE
        typeCC: null, // not to send to BE
        sapOrderId: '',
        salesOrg: '',
        customer: {
            firstName: "",
            lastName: "",
            street: "",
            city: "",
            state: "",
            postalCode: "",
            countryCustomer: "",
            email: "",
            phoneNumber: ""
        },
        card: {
            cardNumber: "",
            cvNumber: "",
            expirationMonth: "",
            expirationYear: "",
            type: ""
        },

        isErrorEmptySapOrderId: false,
        isErrorEmptySalesOrg: false,
        isErrorEmptyFirstName: false,
        isErrorEmptyLastName: false,
        isErrorEmptyStreet: false,
        isErrorEmptyCity: false,
        isErrorEmptyState: false,
        isErrorEmptyPostalCode: false,
        isErrorEmptyPhoneNumber: false,
        isErrorEmptyEmail: false,

        isErrorEmptyCardNumber: false,
        isErrorEmptyCvNumber: false,
        isErrorEmptyExpirationMonth: false,
        isErrorEmptyExpirationYear: false,

        isErrorEmail: false,
        isErrorCardNumber: false,
        isErrorPhoneNumber: false,
        isErrorPostalCode: false,
        isErrorTotalAmount: false,
        isErrorCvNumber: false,
        isErrorExpirationMonth: false,

        isErrorCCExpirated: false
    }

    componentDidMount = () => {
        this.props.getCountries();  
        this.props.getCountriesPayment();  
        this.props.resetPayment();   
        
        if(this.props.pageTransitionClass === 'fade-left' && !this.props.prevPath) {
            this.props.setPageTransitionClassRight();
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if(prevProps.isPaymentSuccess !== this.props.isPaymentSuccess && this.props.isPaymentSuccess) {            
            this.props.history.push('/');
        }

        if(prevState.card.expirationMonth !== this.state.card.expirationMonth || prevState.card.expirationYear !== this.state.card.expirationYear){
            if(this.state.card.expirationMonth && this.state.card.expirationYear)
            this.checkCCValid();
        }
    }

    componentWillUnmount = () => {
        this.props.setPageTransitionClassLeft();
    }

    handleChange = (ev, type, isCustomer = false, isCC = false) => {
      
        // casistica SELECT
        if(type === 'country') {
            return this.setState({
                country: ev,
                currency: ev.currency,
                salesOrg: ev.salesOrg
            })
        }     

        if(type === 'countryCustomer') {
            return this.setState({
                customer: {
                    ...this.state.customer,
                    [type]: ev
                }
            })
        }       

        let value = ev.currentTarget.value;

        //clean errors
        if(value !== "") {
            let error = 'isErrorEmpty' + capitalize(type)
            this.setState({
                [error]: false
            })
        }

        //maxlength
        if(value.length > 100) {
            return;
        }

        switch(type){
            case 'email': {
                if (regex.regexEmail.test(value)){
                    this.setState({isErrorEmail: false})
                } 
            } break;
            case 'cardNumber': {
                if(regex.regexCC.test(value)){
                    this.setState({isErrorCardNumber: false})
                } 
                let typeCC = (regex.regexMastercard.test(value)) ? {type:"Mastercard", codice: '001'}
                        : (regex.regexVisa.test(value)) ? {type:"Visa", codice: '001'}
                        : (regex.regexAmericanX.test(value)) ? {type:"Amex", codice: '001'}
                        : (regex.regexDiscover.test(value)) ? {type:"Discover", codice: '001'}
                        : null;
                this.setState({
                    typeCC
                })
                if (value.length > 25) return;                
            } break;
            case 'phoneNumber':
            //case 'postalCode':
            case 'totalAmount' :{
                let error = 'isError' + capitalize(type)
                if(regex.regexNumeric.test(value)){
                    this.setState({[error]: false})
                } 
            } break;
            case 'cvNumber': {
                if (regex.regexNumeric.test(value) && value.length === 3){
                    this.setState({isErrorCvNumber: false})
                }
                if (value.length > 3) return;
            } break;
            case 'expirationMonth': {  
                if(value < 12) {
                    this.setState({isErrorExpirationMonth: false})
                }            
                if (value.length > 2) return;
            } break;
            case 'expirationYear': {     
                let thisYear = new Date().getFullYear();
                if(value >= thisYear) {
                    this.setState({isErrorCCExpirated: false})
                }
               
                if (value.length > 4) return;
            } break;
        }

        if(isCustomer) {
            return this.setState({
                customer: {
                    ...this.state.customer,
                    [type]: value
                }
            });
        }

        if(isCC) {
            return this.setState({
                card: {
                    ...this.state.card,
                    [type]: value
                }
            });
        }


        this.setState({           
            [type]: value
        });       
    }

    handleBlur = (ev, type, isCustomer = false, isCC = false) => {        
        let value = ev.currentTarget.value

        //clean errors
        if(value === "") {
            let errorEmpty = 'isErrorEmpty' + capitalize(type)
            return this.setState({
                [errorEmpty]: true
            })
        }
        
        switch(type){
            case 'email': {
                if (regex.regexEmail.test(value)){
                    this.setState({isErrorEmail: false})
                } else {
                    this.setState({isErrorEmail: true})
                }
            } break;
            case 'cardNumber': {
                if(regex.regexCC.test(value)){
                    this.setState({isErrorCardNumber: false})
                } else {
                    this.setState({isErrorCardNumber: true})
                }
            } break;
            case 'phoneNumber':
            //case 'postalCode':
            case 'totalAmount':{
                let error = 'isError' + capitalize(type)
                if(regex.regexNumeric.test(value)){
                    this.setState({[error]: false})
                } else {
                    this.setState({[error]: true})
                }
            } break;
            case 'cvNumber': {
                if (regex.regexNumeric.test(value) && value.length === 3){
                    this.setState({isErrorCvNumber: false})
                } else {
                    this.setState({isErrorCvNumber: true})
                } 
               
            } break;
            case 'expirationMonth': {  
                if(value > 12) {
                    this.setState({isErrorExpirationMonth: true})
                } else {
                    this.setState({isErrorExpirationMonth: false})
                }
            } break;
            case 'expirationYear': {  
                let date = new Date();
                let thisYear = date.getFullYear();
                if(value < thisYear) {
                    this.setState({isErrorCCExpirated: true})
                } else {
                    this.setState({isErrorCCExpirated: false})
                }
            } break;
        }
    }

    sendPayment = () => {
        let objToSend = {
            totalAmount: this.state.totalAmount,
            country: this.state.country,            
            sapOrderId: this.state.sapOrderId,
            customer: this.state.customer,
            card: this.state.card            
        }

        this.props.sendPayment(objToSend);
    }

    checkErrors = () => {        
        let state = this.state;
        let isError = false;

        Object.keys(state).map(key => {
            if(key.includes('Error') && state[key]) {
                return isError = true;
            }
        })

        return isError
    }

    checkCCValid = () => {        
        let exMonth = this.state.card.expirationMonth;
        let exYear = this.state.card.expirationYear;
        if( exMonth && exYear) {
            let today, someday;           
            today = new Date();
            someday = new Date();
            someday.setFullYear(exYear, exMonth, 1);

            (someday < today) ? this.setState({isErrorCCExpirated: true}) : this.setState({isErrorCCExpirated: false});
        }        
    }

    render () {        
        let { card, customer, country, sapOrderId, totalAmount } = this.state;
        let checkErrors = this.checkErrors();
        let checkDisabled = card.cardNumber !== '' && card.cvNumber !== '' && card.expirationMonth !== '' && card.expirationYear !== '' && customer.firstName !== '' && customer.lastName !== ''
                            && customer.street !== '' && customer.city !== '' && customer.state !== '' && customer.postalCode !== '' && customer.countryCustomer !== '' && customer.email !== '' && customer.phoneNumber !== '' &&
                            country !== '' && sapOrderId !== '' && totalAmount !== '' && !checkErrors && this.state.salesOrg !== '';
        

        return(
            <div className="row no-gutters payment-page">
                <div className="col-9 bg-light left-half">
                    <Circle />                   
                    <Logo />    
                    <div className="content">
                        <div className="order-info-section box-container">
                            <h2>Order Information</h2>
                            <div className="box row no-gutters">                        
                                <div className="col-6">
                                    <Input                                     
                                        placeholder="Order nr (SAP/ Web ID)" 
                                        inputClass={"input-right" + (this.state.isErrorEmptySapOrderId ? ' error-input' : '') }
                                        handleChange={e => this.handleChange(e, 'sapOrderId')}
                                        handleBlur={e => this.handleBlur(e, 'sapOrderId')}
                                        iconClass={objIcons['sapOrderId']} 
                                        styleType="right-icon"
                                    />
                                     {/* {this.state[error] ? <p className={"input-error-msg " + inputClass}>The sap cid is not in the correct format</p> : null} */}
                                     {this.state.isErrorEmptySapOrderId ? <p className="input-error-msg input-right">This field is required</p> : null}
                                </div> 
                                <div className="col-6">
                                    <Input                                     
                                        placeholder="Sales Org"                                         
                                        iconClass={objIcons['salesOrg']} 
                                        styleType="right-icon"
                                        isReadOnly
                                        value={this.state.salesOrg}
                                        />                                    
                                    {this.state.isErrorEmptySalesOrg ? <p className="input-error-msg">This field is required</p> : null}
                                </div>               
                            </div>
                        </div>
                        <div className="customer-info-section box-container">
                            <h2>Customer Information</h2>
                            <div className="box row no-gutters">
                                {arrayCustomer.map((input, index) => {
                                    let inputClass = index % 2 === 0 ? "input-right" : '';
                                    let error = 'isError' + capitalize(input.value);
                                    let errorEmpty = 'isErrorEmpty' + capitalize(input.value);
                                    let type = (input.value === 'phoneNumber') ? 'number' : 'text';
                                
                                    if(input.value === 'countryCustomer') {
                                        return  <div className="col-6" key={input.value}>
                                                    <CustomSelect 
                                                        placeholder="Country" 
                                                        options={this.props.countries} 
                                                        value={customer.countryCustomer} 
                                                        handleChange={e => this.handleChange(e, input.value, true)} 
                                                        iconClass={objIcons['countryCustomer']} 
                                                        styleType="right-icon"
                                                    />                                
                                                </div>
                                    }
                                    return  <div className="col-6" key={input.value}>
                                                <Input 
                                                    type={type}
                                                    maxLength="100"
                                                    placeholder={input.placeholder} 
                                                    inputClass={inputClass + (this.state[error] || this.state[errorEmpty] ? ' error-input' : '') } 
                                                    value={customer[input.value]} 
                                                    handleChange={e => this.handleChange(e, input.value, true)}
                                                    handleBlur={e => this.handleBlur(e, input.value, true)}
                                                    styleType={inputClass === 'input-right' ? "right-icon" : 'left-icon'}
                                                    iconClass={objIcons[input.value]}
                                                    />
                                                {this.state[error] ? <p className={"input-error-msg " + inputClass}>The {input.value} is not in the correct format</p> : null}
                                                {this.state[errorEmpty] ? <p className={"input-error-msg " + inputClass}>This field is required</p> : null}
                                            </div>
                                })}                           
                            </div>                  
                        </div>                    
                        <div className="box-container payment-info-container">
                            <h2>Payment Information</h2>
                            <div className="box row no-gutters">
                                <div className="col-6">
                                    <CustomSelect 
                                        placeholder="Payment Country" 
                                        options={this.props.paymentCountries} 
                                        value={this.state.country} 
                                        handleChange={e => this.handleChange(e, 'country')} 
                                        iconClass={objIcons['country']}  
                                        styleType="right-icon"
                                    />                                
                                </div>
                                <div className="col-6 currency-input">
                                    <Input 
                                        type="number"                                  
                                        placeholder="Amount To Pay" 
                                        value={this.state.totalAmount} 
                                        inputClass=""
                                        handleChange={e => this.handleChange(e, 'totalAmount')} 
                                        iconClass={objIcons['totalAmount']} 
                                        styleType="left-icon"
                                    />
                                    <span className="currency-span">{objCurrency[this.state.currency]}</span>
                                </div>
                            </div>
                        </div> 
                    </div>
                             
                </div>                
                <div className="col-3 bg-dark right-half sidebar">
                    <div className="header-sidebar">
                        <div className="header-content">
                            <div className="row no-gutters">
                                <div className="col-4 header-items amount-label">You are paying: </div> <div className="col-8 header-items amount">{this.state.totalAmount} {objCurrency[this.state.currency]}</div> 
                                <div className="col-4 header-items country-label">Country: </div> <div className="col-8 header-items country">{this.state.country.country}</div> 
                            </div>                                                 
                        </div>
                    </div>                   
                    <div className="credit-card-info-section">
                        <h2>Credit Card Detail</h2>
                        <div className="credit-card-info-container">   
                            <div className="input-container">
                                {arrayCC.map(input => {                                   
                                    let error = 'isError' + capitalize(input.value);
                                    let errorEmpty = 'isErrorEmpty' + capitalize(input.value);
                                    let ccImage = (input.value === 'cardNumber' && this.state.typeCC) ? ' cc-image ' + this.state.typeCC.type  : '';
                                   
                                    return  <div className={"single-input" + ccImage} key={input.value}>
                                                <Input 
                                                    type="number"                                                   
                                                    placeholder={input.placeholder} 
                                                    inputClass={'input-light ' + (this.state[error] || this.state[errorEmpty] ? 'error-input' : '')} 
                                                    value={card[input.value]} 
                                                    handleChange={e => this.handleChange(e, input.value, false, true)}
                                                    handleBlur={e => this.handleBlur(e, input.value, false, true)}
                                                    styleType="right-icon" 
                                                    iconClass={ objIcons[input.value]}
                                                    />
                                                {this.state[error] ? <p className="input-error-msg">The {input.value} is not in the correct format</p> : null}
                                                {this.state[errorEmpty] ? <p className="input-error-msg">This field is required</p> : null}
                                            </div>
                                })}     
                                {this.state.isErrorCCExpirated ? <p className="input-error-msg cc-expired">The credit card is expirated</p> : null}                              
                            </div>  
                            <div className="footer-sidebar">
                                <div className="btn-container">
                                    <Button btnClass="big-button" disabled={!checkDisabled} isArrow handleClick={this.sendPayment.bind(this)}>Pay</Button>  
                                </div>
                                {this.props.isPaymentLoading ? <Loader /> : null}                                
                            </div>     
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {        
        countries: state.payment.countries,  
        paymentCountries: state.payment.paymentCountries,
        pageTransitionClass: state.animation.pageTransitionClass,
        prevPath: state.animation.prevPath,
        isPaymentSuccess: state.payment.isPaymentSuccess,
        isPaymentLoading: state.payment.isPaymentLoading,
        response: state.payment.response
    }
};

const mapDispatchToProps = dispatch => {
    return {       
        getCountries: () => dispatch(actions.getCountries()),
        getCountriesPayment: () => dispatch(actions.getCountriesPayment()),
        setPageTransitionClassLeft: () => dispatch(actions.setPageTransitionClassLeft()),
        setPageTransitionClassRight: () => dispatch(actions.setPageTransitionClassRight()),
        sendPayment: obj => dispatch(actions.sendPayment(obj)),
        resetPayment: () => dispatch(actions.resetPayment())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
