import * as actionTypes from './actionTypes';

export const setPageTransitionClassLeft = message => {
    return { 
        type: actionTypes.SET_TRANSITION_CLASS_LEFT,
        message
    };
};

export const setPageTransitionClassRight = () => {
    return { 
        type: actionTypes.SET_TRANSITION_CLASS_RIGHT,       
    };
};

export const setPrevPath = prevPath => {
    return {
        type: actionTypes.SET_PREV_PATH,
        prevPath
    }
};