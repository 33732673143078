import React from 'react';
import CustomIcon from '../ui-components/CustomIcon';

const input = props => (
    <React.Fragment>
        {props.styleType === 'left-icon'
        ?   <CustomIcon iconClass={props.iconClass}/>
        : null}
        <input 
            type={props.type} 
            className={props.inputClass + (props.isReadOnly ? " readonly" : "")} 
            placeholder={props.placeholder} 
            onChange={props.handleChange} 
            onBlur={props.handleBlur}
            value={props.value}            
            maxLength={props.maxLength}
            min={props.min ? props.min : null}
            readOnly={props.isReadOnly ? true : false}
        />
        {props.styleType === 'right-icon'
        ?   <CustomIcon iconClass={props.iconClass}/>
        : null}
    </React.Fragment>   
)

export default input;


// (props.type === 'number' )?  : maxLength=props.maxLength