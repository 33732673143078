import React, { Component } from 'react';
import {
	Redirect,
	Switch,
	Route,
	withRouter
} from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from './store/actions';
import WithErrorHandling from './components/utils/ErrorHandling/WithErrorHandling';
import PrivateRoute from './components/utils/PrivateRoute/PrivateRoute';
import PaymentPage from './components/PaymentPage/PaymentPage';
import LoginPage from './components/LoginPage/LoginPage';
import Homepage from './components/Homepage/Homepage';
import { TransitionGroup, CSSTransition } from "react-transition-group";


class App extends Component {

	render() {
		let user = this.props.user;
		let location = this.props.location;

		return (
			<WithErrorHandling showError={this.props.showError} closeAlert={this.props.closeAlert}>
				{/* <TransitionGroup className="transition-group">
						<CSSTransition
							key={location.key}
							timeout={{ enter: 300, exit: 300 }}
							classNames={this.props.pageTransitionClass}
							>
							<section className="route-section"> */}
								<Switch location={location}>
									<PrivateRoute exact path = "/payment" component={PaymentPage} user={user} history={this.props.history} />
									<PrivateRoute exact path = "/" component={Homepage} user={user} history={this.props.history} />
									<Route exact path="/login" component={LoginPage} />
									<Redirect to="/login" />
								</Switch>
							{/* </section>
						</CSSTransition>
 					</TransitionGroup> */}
 			</WithErrorHandling>
		);
	}
}

const mapStateToProps = state => {
	return {
		user: state.authentication.user,
		showError: state.error.showError,
		pageTransitionClass: state.animation.pageTransitionClass,
		isAuthenticated: state.authentication.isAuthenticated
	};
};

const mapDispatchToProps = dispatch => {
    return {
        closeAlert: () => dispatch(actions.closeAlert()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (App));
