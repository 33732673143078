import * as actionTypes from './actionTypes';
import * as urlList from '../../config';
import { formatOptions, handleErrorsJson } from '../../services/Api';
import { showError } from './errorActions';
import cookie from 'react-cookies';

export const getCountries = () => {
    return dispatch => {
        let url = urlList.GET_COUNTRIES_URL;
        fetch(url, {
            method:'get',
            headers: {
                "accessToken": cookie.load('accessToken'),
                "jwtToken": cookie.load('jwtToken')
            }
        }).then( handleErrorsJson )
        .then( json => {
            if(json) json = formatOptions(json, 'country');
            dispatch(getCountriesSuccess(json));
        }).catch(err => {
            dispatch(showError(err));
        });
    }
};

const getCountriesSuccess = countries => {
    return {
        type: actionTypes.GET_COUNTRIES_SUCCESS,
        countries
    };
};

export const getCountriesPayment = () => {
    return dispatch => {
        let url = urlList.GET_ENABLED_COUNTRIES_URL;

        fetch(url, {
            method:'get',
            headers: {
                "accessToken": cookie.load('accessToken'),
                "jwtToken": cookie.load('jwtToken')
            }
        }).then( handleErrorsJson )
        .then( json => {
            if(json) json = formatOptions(json, 'country');
            dispatch(getCountriesPaymentSuccess(json));
        }).catch(err => {
            dispatch(showError(err));
        });
    }
};

const getCountriesPaymentSuccess = countries => {
    return {
        type: actionTypes.GET_ENABLE_COUNTRIES_SUCCESS,
        countries
    };
};

export const sendPayment = params => {

    return dispatch => {
        dispatch(sendPaymentRequest());
        return fetch(urlList.SEND_PAYMENT_URL, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "accessToken": cookie.load('accessToken'),
                "jwtToken": cookie.load('jwtToken')
                // "X_AUTH_LUXOTTICATOKEN": getCookie('X_AUTH_LUXOTTICATOKEN')
            },
            body: JSON.stringify(params)
        }).then( handleErrorsJson )
        .then(response => {
            dispatch(sendPaymentSuccess(response.response))
        }).catch(err => {
            dispatch(showError(err));
            dispatch(sendPaymentFailure())
        });
    }
};

const sendPaymentRequest = () => {
    return {
        type: actionTypes.SEND_PAYMENT_REQUEST,
    };
};

const sendPaymentSuccess = response => {
    return {
        type: actionTypes.SEND_PAYMENT_SUCCESS,
        response
    };
};

const sendPaymentFailure = () => {
    return {
        type: actionTypes.SEND_PAYMENT_FAILURE
    };
};


export const resetPayment = () => {
    return {
        type:actionTypes.RESET_PAYMENT
    }
};