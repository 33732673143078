

export const regex = {
    regexEmail: /^[a-zA-Z0-9.!#$%&'*+\=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])+(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/,
    regexCC: /[0-9]{12,19}/,
    regexNumeric: /^[0-9]+$/,
    regexAlpha: /^[A-Z a-z]+$/,
    regexMastercard: /^5[1-5]/,
    regexAmericanX: /^3[47]/,
    regexVisa: /^4/,
    regexDiscover: /^6/
}