import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../services/Api';
import * as _ from 'lodash';

const initialState = {
    countries: [],
    paymentCountries: [],
    isPaymentLoading: false,
    isPaymentSuccess: false,
    response: null
};

const setCountries = (state, action) => {
    return updateObject( state, {
        countries: action.countries
    });
};

const setCountriesPayment = (state, action) => {
    return updateObject( state, {
        paymentCountries: action.countries
    });
};

const sendPaymentRequest = state => {
    return updateObject( state, {
        isPaymentLoading: true,
        isPaymentSuccess: false
    });
};

const sendPaymentSuccess = (state, action) => {    
    return updateObject( state, {
        isPaymentLoading: false,
        isPaymentSuccess: true,
        response: action.response
    });
};

const sendPaymentFailure = (state, action) => {
    return updateObject( state, {
        isPaymentLoading: false
    });
};

const resetPayment = state => {
    return updateObject( state, {
        isPaymentLoading: false,
        isPaymentSuccess: false,
        response: null
    });
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {  
        case actionTypes.GET_COUNTRIES_SUCCESS: return setCountries(state, action);    
        case actionTypes.GET_ENABLE_COUNTRIES_SUCCESS: return setCountriesPayment(state,action);  
        case actionTypes.SEND_PAYMENT_REQUEST: return sendPaymentRequest(state);      
        case actionTypes.SEND_PAYMENT_SUCCESS: return sendPaymentSuccess(state, action);       
        case actionTypes.SEND_PAYMENT_FAILURE: return sendPaymentFailure(state, action);    
        case actionTypes.RESET_PAYMENT: return resetPayment(state);   
        default:
            return state;
    }
};

export default reducer;