import * as _ from 'lodash';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

/*** FORMAT OPTIONS WHEN ARRAY OF OBJ ***/
export const formatOptions = (optionList, labelProp) => {
    return _.orderBy(optionList.map(item => {
       return {
           ...item,
           label: item[labelProp], 
           value: item[labelProp]
       }
   }), ['label']);
}

/*** FORMAT OPTIONS WHEN ARRAY OF STRING ***/
export const formatOptionsArray = optionList => {
    return _.orderBy(optionList.map(item => {
        return {
            label: item, 
            value: item
        }
    }), ['label']);
}

  
/* HANDLE ERROR */
export const handleErrors = response => {
    if (!response.ok) {
        response.json().then(res => {
            throw Error('Request rejected with status '+ res.status + " Msg: " + res.message);
        })
    }
    return response;
}

/* HANDLE ERROR */
export const handleErrorsJson = response => {
    if (response.ok) {
        return response.json();
    } else {
        throw new Error('Something went wrong');
    }
}