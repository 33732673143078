import React from 'react';
import arrowIcon from '../../img/arrow.svg';
 
const customButton = props => (
    <button className={"button " + props.btnClass + (props.disabled ? ' disabled':'')} onClick={props.handleClick} disabled={props.disabled}>
        {props.children}
        {props.isArrow ? <img src={arrowIcon} /> : null}
    </button>                      
)

export default customButton;